@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prompt/v10/-W__XJnvUD7dzB26Zw.ttf) format('truetype');
}
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Ck_k4bg.ttf) format('truetype');
}
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cv_44bg.ttf) format('truetype');
}
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2C2_84bg.ttf) format('truetype');
}
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cx_w4bg.ttf) format('truetype');
}
