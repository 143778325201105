@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600;700;800&display=swap');
.App {
  text-align: center;
}
body {
  font-family: Prompt, Arial, sans-serif;
}
.ant-btn {
  box-shadow: 3px 3px 6px #00000029;
  border-radius: 50px;
  padding: 4px 30px;
}
.ant-input {
  border: 1px solid #b9b9b9;
  border-radius: 50px;
}

@primary-color: #525ca3;